<template>
  <div v-if="meta.last_page > 1 && meta.last_page >= meta.current_page" class="flex space-x-2">
    <TertiaryButton
      compact
      :disabled="!updatedMeta.prev_page_url"
      :href="json ? undefined : updatedMeta.prev_page_url"
      @click="emit('load', updatedMeta.prev_page_url)"
    >
      <ChevronLeftIcon class="w-4 h-4" />
      <span>{{ $t("pagination.previous") }}</span>
    </TertiaryButton>
    <TertiaryButton
      v-for="(link, index) in links"
      :key="`page-link-${link.url ? link.label : '_'}-${index + 1}`"
      compact
      :disabled="!link.url"
      :href="json ? undefined : link.url"
      :class="{
        'bg-gray-300 cursor-default': link.active,
        'bg-transparent pointer-events-none text-gray-500 font-medium': link.label === '...',
      }"
      @click="link.active ? undefined : emit('load', link.url)"
    >
      {{ link.label }}
    </TertiaryButton>
    <TertiaryButton
      compact
      :href="json ? undefined : updatedMeta.next_page_url"
      :disabled="!updatedMeta.next_page_url"
      @click="emit('load', updatedMeta.next_page_url)"
    >
      <span>{{ $t("pagination.next") }}</span>
      <ChevronRightIcon class="w-4 h-4" />
    </TertiaryButton>
  </div>
</template>

<script setup>
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"
import { computed } from "vue"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid"
const props = defineProps({
  json: Boolean,
  meta: Object,
  scrollTarget: String,
  // meta example: {
  //   "current_page":1,
  //   "from":1,
  //   "last_page":2,
  //   "links":[
  //     {"url":null,"label":"&laquo; Previous","active":false},
  //     {"url":"/","label":"1","active":true},
  //     {"url":"/","label":"2","active":false},
  //     {"url":"/","label":"Next &raquo;","active":false}
  //   ],
  //   "path":"#",
  //   "per_page":10,
  //   "to":10,
  //   "total":17,
  //   "first_page_url":"#",
  //   "last_page_url":"#",
  //   "prev_page_url":null,
  //   "next_page_url":"#",
  // }
})
const emit = defineEmits(["load"])
const updatedMeta = computed(() => {
  const updateLink = link => (props.scrollTarget && link ? link + "#" + props.scrollTarget : link)

  return {
    ...props.meta,
    first: updateLink(props.meta.first_page_url),
    last: updateLink(props.meta.last_page_url),
    prev: updateLink(props.meta.prev_page_url),
    next: updateLink(props.meta.next_page_url),
    links: props.meta.links.map(link => ({
      ...link,
      url: updateLink(link.url),
    })),
  }
})
const links = computed(() => {
  const lastPage = updatedMeta.value.last_page
  const currentPage = updatedMeta.value.current_page

  const pages = []

  // First we aggregate first page, last page and those pages that are 1 step away from the current page
  let n = 1
  for (n = 1; n <= lastPage; n++) {
    if (n === 1 || n === lastPage || Math.abs(n - currentPage) <= 1) {
      pages.push(n)
    }
  }

  // Then we will go through the pages array and find out the difference between two adjacent elements.
  // If they are 2 steps away from each other we insert the middle page number between them. And if
  // they are more than 2 steps away we insert '...' between them.
  n = 1
  while (n < pages.length) {
    if (pages[n] - pages[n - 1] === 2) {
      pages.splice(n, 0, pages[n] - 1)
      n++
    }
    if (pages[n] - pages[n - 1] > 2) {
      pages.splice(n, 0, "...")
      n++
    }
    n++
  }

  // Finally we map the page numbers into real links
  return pages.map(page => {
    if (page === "...")
      return {
        label: "...",
      }
    return updatedMeta.value.links.find(l => +l.label === page)
  })
})
</script>
